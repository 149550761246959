'use strict';

const forgeUiComponent = require('../framework/ui-component');

// TODO: DRY pop-up configuration
const WINDOW_WIDTH = 400;
const CORE_POPUP_OPTIONS = [
  'resizable',
  'width=' + WINDOW_WIDTH,
  'height=640',
  'alwaysRaised'
];

const NewsletterLinkHandler = forgeUiComponent('GetNewsletter', {}, function NewsletterHandlerConstructor() {
  const self = this;

  const newsletterLink = this.queryProperty('link') || document.createElement('a');

  newsletterLink.addEventListener('click', function (e) {
    self.popupSubscribeForm(newsletterLink.href);
    e.preventDefault();
  }, false);
});

NewsletterLinkHandler.prototype.popupSubscribeForm = function popupSubscribeForm(url) {
  const posX = ((window.outerWidth / 2) + window.screenX) - Math.floor(WINDOW_WIDTH / 2);
  const posY = window.screenY + 40;

  const finalFeatures = CORE_POPUP_OPTIONS.concat([
    'left=' + posX,
    'top=' + posY
  ]).join(',');

  window.open(url, '_blank', finalFeatures);
};

module.exports = NewsletterLinkHandler;
